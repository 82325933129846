<template>
    <section class="section-titulos-component py-3">
        <div class="row mx-0">
            <div class="text-center" style="width:35px;">
                <el-tooltip content="Inactivo" placement="bottom" effect="light">
                    <i v-if="!data.estado" class="icon-eye-off f-18 text-general cr-pointer" />
                </el-tooltip>
            </div>
            <div class="col px-0">
                <div class="row mx-0 align-items-center text-gris2 f-500 mb-2">
                    Sección título
                </div>
                <template v-if="data.tipo == 21">
                    <div class="row mx-0" :class="`${data.parametro == 21 ? 'justify-content-start' : data.parametro == 22 ? 'justify-content-center' : data.parametro == 23 ? 'justify-content-end' : ''}`">
                        <div class="col-auto text-general f-28 f-600">
                            {{ data.titulo }}
                        </div>
                    </div>
                </template>
                <template v-else-if="data.tipo == 22">
                    <div class="row mx-0" :class="`${data.parametro == 21 ? 'justify-content-start' : data.parametro == 22 ? 'justify-content-center' : data.parametro == 23 ? 'justify-content-end' : ''}`">
                        <p class="col-auto px-0 text-general f-28 f-600">
                            {{ data.titulo }}
                        </p>
                        <div class="col-12" />
                        <div class="col-auto bg-general" style="width:44px;height:5px;" />
                    </div>
                </template>
                <template v-else-if="data.tipo == 23">
                    <div class="row mx-0" :class="`${data.parametro == 21 ? 'justify-content-start' : data.parametro == 22 ? 'justify-content-center' : data.parametro == 23 ? 'justify-content-end' : ''}`">
                        <div class="col-auto px-0 d-flex">
                            <div class="space-title px-2 text-general f-28 f-600">
                                {{ data.titulo }}
                                <div class="bg-title" />
                            </div>
                            <div class="vertical-bar-01" />
                            <div class="vertical-bar-02" />
                            <div class="vertical-bar-03" />
                        </div>
                    </div>
                </template>
            </div>
            
            <div class="col-auto px-0 d-flex ml-3">
                <button type="button" class="btn-action border mr-2">
                    <el-popover
                    ref="optionsPopover"
                    placement="bottom"
                    trigger="click"
                    width="200"
                    popper-class="p-0 br-none"
                    >
                        <div class="row mx-2 my-2 item-option align-items-center px-3 cr-pointer" @click="emitirAccion('editar')">
                            Editar
                        </div>
                        <div class="row mx-2 my-2 item-option align-items-center px-3 cr-pointer" @click="emitirAccion('ordenar')">
                            Ordenar sección
                        </div>
                        <div v-if="data.estado == 1" class="row mx-2 my-2 item-option align-items-center px-3 cr-pointer" @click="emitirAccion('activar')">
                            Inactivar
                        </div>
                        <div v-if="data.estado == 0" class="row mx-2 my-2 item-option align-items-center px-3 cr-pointer" @click="emitirAccion('inactivar')">
                            Activar
                        </div>
                        <div class="row mx-2 my-2 item-option align-items-center px-3 cr-pointer" @click="emitirAccion('eliminar')">
                            Eliminar
                        </div>
                        <i slot="reference" class="icon-dots-vertical f-20 text-general cr-pointer" />
                    </el-popover>
                </button>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    props: {
        data:{
            type:Object,
            default:() => {}
        },
    },
    data(){
        return {
            activeComponent: false
        }
    },
    methods:{
        emitirAccion(tipo){
            let accion = { tipo:tipo, idSeccion: this.data.id, seccion:'titulo' };
            this.$refs.optionsPopover.doToggle();
            this.$emit('acciones', accion);
        },
    },
}
</script>
<style lang="scss" scoped>

.section-titulos-component:hover{
    background-color: rgb(220, 242, 252);
}

.space-title{
    height: 44px;
    position: relative;
    //background-color: rgb(216, 207, 244);
    display: flex;
    align-items: center;
    justify-content: center;
    .bg-title{
        background-color: var(--color-general);
        opacity: 0.2;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0px;
        top: 0px;
    }
}
.vertical-bar{
        background-color: var(--color-general);
        opacity: 0.2;
        height: 44px;
        width: 10px;
        &-01{
            @extend .vertical-bar;
            margin-left: 5px;
            margin-right: 5px;
            /* right: -16px; */
        }
        &-02{
            @extend .vertical-bar;
            margin-right: 5px;
            /* right: -28px; */
        }
        &-03{
            @extend .vertical-bar;
            margin-right: 5px;
            /* right: -40px; */
        }
    }

</style>
